<template>
  <UtilRouteLink
    :link="link"
    :target="target"
    class="atom-link"
    :class="[
      `is-icon-${iconPosition}`,
      { 'is-disabled': isDisabled },
      { 'has-icon': icon !== 'none' },
      { 'is-inverted': isInverted },
      { 'external-link': icon !== 'arrow-up-right' },
      { 'internal-link': icon !== 'chevron-right' },
    ]"
  >
    <div class="atom-link__inner">
      <span
        class="atom-link__text"
      >{{ text }}</span>
      <IonIcon
        v-if="icon !== 'none'"
        :icon-name="icon"
        :color-schema="colorSchema"
        class="atom-link__icon"
      />
    </div>
  </UtilRouteLink>
</template>

<script setup>
defineProps({
    /*
        Link data
    */
    link: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    target: {
        type: String,
        default: '_self',
        validator: (value) => ['_blank', '_self'].includes(value),
    },

    /*
        Text
    */
    text: {
        type: String,
        default: 'Link Text',
        validator: (value) => value.length > 0,
    },

    icon: {
        type: String,
        default: 'none',
        validator: (value) => [
            'none',
            'chevron-right',
            'chevron-left',
            'arrow-up-right',
        ].includes(value),
    },

    iconPosition: {
        type: String,
        default: 'right',
        validator: (value) => [
            'left',
            'right',
        ].includes(value),
    },

    isInverted: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
        States
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    colorSchema: {
        type: String,
        default: 'orange',
    },
});

const colorTopLevel = inject('colorSchema');
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>

<style lang="scss" scoped>
.atom-link__text {
    position: relative;
    padding-top: 2px;
    color: v-bind(color4);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    .block-header__wrapper & {
        color: var(--c-white);
        font-family: var(--f-family--thin);
    }

    .storyblok-teaser-box__link & {
        font-family: var(--f-family--bold);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    .block-flyout-contact__content-main-contact-link & {
        @include z-index('navFlyout');

        font-family: var(--f-family--bold);
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);
        white-space: nowrap;
    }

    .block-nav-links__content-main-links-link & {
        font-family: var(--f-family--regular);
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);
    }

    .block-nav-links-mobile__content-main-links-link & {
        font-family: var(--f-family--regular);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    .block-nav-links-mobile__main-link & {
        font-family: var(--f-family--regular);
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);
    }

    .block-nav-links__content-main-sub-links &
     {
        font-family: var(--f-family--regular);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    .block-nav-links-mobile__content-main-sub-links &
     {
        font-family: var(--f-family--regular);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    .block-flyout-regions-mobile__title & {
        font-family: var(--f-family--regular);
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);
    }

    .block-item-info-programm & {
        font-family: var(--f-family--bold);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    .block-footer & {
        font-family: var(--f-family--thin);
    }
}

.atom-link__inner {
    position: relative;
    display: flex;
    align-items: center;
    align-self: flex-start;
    color: var(--c-secondary--light);
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--black);
    line-height: 1.5;
    transition:  column-gap 0.5s $EASE_OUT--BACK;

    .block-item-info-programm & {
        column-gap: 5px;
    }

    .is-icon-left & {
        flex-direction: row-reverse;
    }

    @include mobile {
        max-width: 278px;
    }
}

.atom-link {
    display: flex;

    .block-footer & {
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    .isRtl & {
        justify-content: end;
        direction: ltr;
    }

    &.block-flyout-regions__link  {
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);
    }

    &.is-disabled {
        opacity: 0.2;
        pointer-events: none;
    }

    &:hover {
        .atom-link__inner {
            column-gap: 20px;
        }

        .atom-link__text {
            &:after {
                opacity: 1;
            }
        }
    }

}

.internal-link {
    .block-item-info-programm & {
        .atom-link__icon {
            transition: transform 0.5s $EASE_OUT--BACK;
        }

        &:hover {
            .atom-link__inner {
                column-gap: 5px;
            }

            .atom-link__icon {
                transform: translateX(10px);
            }
        }
    }
}

/* stylelint-disable-next-line no-descending-specificity */
.atom-link__icon {
    flex-shrink: 0;
    margin-top: -1px;
    transition: margin 0.5s $EASE_OUT--BACK;
}
</style>

<template>
  <div class="atom-image__wrapper">
    <div class="atom-image__background">
      <NuxtImg
        v-if="!isSvg"
        :class="[
          isLoaded ? 'is-loaded' : '',
          lazy ? 'has-lazy' : '',
        ]"
        class="atom-image"
        v-bind="{ ...mergedProps }"
        @load="showImage()"
      />
      <img v-else :src="mergedProps.src" :alt="mergedProps.alt" />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    /* Image Data */
    image: {
        type: Object,
        required: true,
        validator: (value) => typeof value === 'object',
    },

    provider: {
        type: String,
        default: 'ipx',
        required: true,
        validator: (value) => ['ipx', 'storyblok'].includes(value),
    },

    /* Options */
    width: {
        type: [String, Boolean],
        default: '1000px',
    },

    height: {
        type: [String, Boolean],
        default: '',
    },

    aspectRatio: {
        type: [String, Boolean],
        default: false,
    },

    lazy: {
        type: Boolean,
        default: true,
    },

    lazyDelay: {
        type: Number,
        default: 0,
    },

    background: {
        type: [String, Boolean],
        default: false,
    },

    preload: {
        type: Boolean,
        default: false,
    },

    format: {
        type: String,
        default: 'webp',
        validator: (value) => ['webp', 'jpg', 'png'].includes(value),
    },

    smartCrop: {
        type: Boolean,
        default: false,
        validator: (value) => typeof (value) === 'boolean',
    },

    focus: {
        type: [String, Boolean],
        default: false,
    },
});

/*
    Lazy loading
*/
const isLoaded = ref(false);
const showImage = async () => {
    if (props.lazy) {
        await sleep(props.lazyDelay);
    }
    isLoaded.value = true;
};

/* Lazy loading background */
const backgroundColor = computed(() => {
    if (props.background) {
        return props.background;
    }

    return 'transparent';
});

/*
    Build props for Nuxt Image component
*/
const generalProps = computed(() => ({
    provider: props.provider,
    loading: props.lazy ? 'lazy' : 'eager',
    preload: props.preload,
}));

const buildStoryblokProps = () => {
    const modifiers = {
        filters: {},
    };

    if (props.smartCrop) {
        modifiers.smart = true;
    }

    if (props.focus && !props.smartCrop) {
        modifiers.filters.focal = props.focus;
    }

    if (props.image.focus) {
        modifiers.filters.focal = props.image.focus;
    }

    const dimensions = {
        width: props.width ? props.width : null,
        height: props.height ? props.height : null,
    };

    if (props.aspectRatio) {
        const width = parseInt(props.width, 10);
        const [ratioX, ratioY] = props.aspectRatio.split(':');
        dimensions.width = `${width}px`;
        dimensions.height = `${(width / ratioX) * ratioY}px`;
    }

    return {
        src: props.image?.filename,
        alt: props.image?.alt,
        format: props.format,
        modifiers,
        ...dimensions,
    };
};

const buildIpxProps = () => ({
    src: props.image?.src,
    alt: props.image?.alt,
    width: props.width ? props.width : null,
    height: props.height ? props.height : null,
});

const imageProps = computed(() => {
    switch (props.provider) {
    case 'storyblok':
        return buildStoryblokProps();
    case 'ipx':
    default:
        return buildIpxProps();
    }
});

const mergedProps = computed(() => ({
    ...generalProps.value,
    ...imageProps.value,
}));

/*
    Check for svg
*/
const isSvg = computed(() => mergedProps.value?.src?.includes('.svg'));
</script>

<style lang="scss" scoped>
.atom-image__wrapper {
    display: flex;

    .block-flyout-contact__content-main-contact &{
        @include tablet {
            display: none;
        }
    }
}

.atom-image__background {
    display: flex;
    height: 100%;
    align-self: flex-start;
    background: v-bind(backgroundColor);

    img {
        .block-item-contact-card &,
        .detail-card & {
            @include tablet {
                aspect-ratio: 1/1;
            }
        }
    }

    .storyblok-image & {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .block-download-hub__filter-primary-departments-image & {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .block-key-figures__image-section & {
        width: 100%;
        height: 100%;
    }

    .block-form-contact__person-card-image & {
        width: 100%;
    }
}

.atom-image {
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.is-loaded {
        opacity: 1;
    }

    .block-download-hub__filter-primary-departments-image & {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .block-key-figures__image-section & {
        width: 100%;
        height: 100%;
    }

    .block-form-contact__person-card-image & {
        width: 100%;
        height: 360px;

        @include tablet {
            height: 100%;
        }
    }

    .block-form-contact__person-card-image.single & {
        width: 100%;
        height: 360px;

        @include tablet {
            height: 100%;
            object-position: top;
        }
    }

    .block-form-contact__person-card-image.single.is-logo & {
        width: 100%;
        height: 360px;

        @include tablet {
            height: 100%;
            object-position: unset;
        }
    }
}
</style>

<template>
  <NuxtLink
    v-if="!isExternalLink && !isStorybook"
    :to="isEmail ? 'mailto:' + link : localePath(slug)"
    :rel="target === '_blank' ? 'noopener noreferrer' : null"
    :target="target"
    class="atom-link"
    @click="closeFlyout()"
    @keypress="closeFlyout()"
  >
    <slot />
  </NuxtLink>
  <a
    v-else
    :href="slug"
    rel="noopener noreferrer"
    target="_blank"
    class="atom-link"
  >
    <slot />
  </a>
</template>

<script setup>
const props = defineProps({
    link: {
        type: String,
        default: '',
    },

    target: {
        type: String,
        default: '_self',
        validator: (value) => ['_blank', '_self'].includes(value),
    },
});

const isExternalLink = computed(
    () => props.link.includes('http://')
    || props.link.includes('https://')
    || props.link.includes('mailto:')
    || props.link.includes('tel:'),
);

// Check if link is Email
const isEmail = computed(() => props.link.includes('@'));

/*
  Internationalization
*/
const { locale } = useI18n();
const slugsStore = useSlugsStore();
const slug = computed(
    () => (isExternalLink.value
        ? props.link
        : slugsStore.getTranslatedSlug(props.link, locale.value)
    ),
);

/*
  Internationalization
*/
/*
  This is a workaround for Storybook until this issue is closed:
  https://github.com/storybook-vue/nuxt/issues/7
*/
const isStorybook = process.env.IS_STORYBOOK;

const flyoutStore = useFlyoutStore();
const closeFlyout = () => {
    flyoutStore.setFlyoutState(false);
    flyoutStore.resetFlyoutData();
};
const localePath = useLocalePath();
</script>
